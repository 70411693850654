import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";


// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGINCVTAS = "loginCvtas";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
// cvtas actions
export const GET_ALERTS = "getAlerts";
export const ADD_ALERT = "addAlert";
export const EDIT_ALERT = "editAlert";
export const GET_VARIABLES = "getVariables";
export const GET_ALL_VARIABLES = "getAllVariables";
export const GET_NOTIFICATIONS = "getNotifications";
export const GET_STATIONS = "getStations";
export const GET_LINKS = "getLinks";

// mutation types
export const SET_STATIONS = "setStations";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken()
};

const getters = {
    // currentUser(state) {
    //     return state.user;
    // },
    // isAuthenticated(state) {
    //     return state.isAuthenticated;
    // }
};

const actions = {
    // -------- ALERTS -------
    [GET_ALERTS](context, payload = {}) {
        return new Promise(resolve => {
            console.log(context, payload);
            ApiService.get("?action=list&object=alert")
                .then((result) => {
                    resolve(result);
                })
                .catch((response) => {
                    console.log(response)
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [ADD_ALERT](context, params) {
        return new Promise(resolve => {
            ApiService.post("?action=add&object=alert", params)
                .then((response) => {
                    // context.commit(SET_AUTH, data);
                    resolve(response);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [EDIT_ALERT](context, params) {
        return new Promise(resolve => {
            ApiService.post("?action=edit&object=alert&key=" + params.id, params)
                .then((response) => {
                    // context.commit(SET_AUTH, data);
                    resolve(response);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [GET_STATIONS](context, payload = {}) {
        return new Promise(resolve => {
            console.log(context, payload);
            ApiService.get("?action=list&object=all_stations")
                .then((result) => {
                    resolve(result);
                })
                .catch((response) => {
                    console.log(response)
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [GET_NOTIFICATIONS](context, payload) {
        return new Promise(resolve => {
            console.log(context, payload);
            ApiService.get("?object=notification&action=list&order=created_at&ordertype=DESC&recperpage=30&to=" + JwtService.getUserEmail())
                .then((result) => {
                    resolve(result);
                })
                .catch((response) => {
                    console.log(response)
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [GET_ALL_VARIABLES](context, payload = {}) {
        return new Promise(resolve => {
            console.log(context, payload);
            ApiService.get("?action=list&object=variable")
                .then((result) => {
                    resolve(result);
                })
                .catch((response) => {
                    console.log(response)
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [GET_VARIABLES](context, payload) {
        return new Promise(resolve => {
            console.log('get_naked: ', payload);
            ApiService.getNaked(payload)
                .then((result) => {
                    resolve(result);
                })
                .catch((response) => {
                    console.log(response)
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    // ------------
    // LINKS
    // ------------
    [GET_LINKS](context, payload = {}) {
        return new Promise(resolve => {
            console.log(context, payload);
            ApiService.get("?action=list&object=links&order=id&ordertype=ASC")
                .then((result) => {
                    resolve(result);
                })
                .catch((response) => {
                    console.log(response)
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
};

const mutations = {
    [SET_STATIONS](state, data) {
        state.stationsList = data;
    },
    // [SET_AUTH](state, user) {
    //     state.isAuthenticated = true;
    //     state.user = user;
    //     state.errors = {};
    //     JwtService.saveToken(state.user.token);
    // },
    // [SET_PASSWORD](state, password) {
    //     state.user.password = password;
    // },
    // [PURGE_AUTH](state) {
    //     state.isAuthenticated = false;
    //     state.user = {};
    //     state.errors = {};
    //     JwtService.destroyToken();
    // }
};

export default {
    state,
    actions,
    mutations,
    getters
};
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";


// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGINCVTAS = "loginCvtas";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken()
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("login", credentials)
                .then(({ data }) => {
                    console.log('isPushNotificationsEnabledVerbose()');
                    // ------ ONESIGNAL -------
                    // OneSignal.setEmail(rootState.AppActiveUser.email);
                    Promise.all([
                            OneSignal.isPushNotificationsEnabled(),
                            OneSignal.getUserId(),
                            OneSignal.getEmailId(),
                            OneSignal.getRegistrationId(),
                            OneSignal.getNotificationPermission(),
                            OneSignal.isOptedOut(),
                            // OneSignal.context.serviceWorkerManager.getActiveState()
                        ])
                        .then(([isSubscribed, userId, emailId, registrationId, notificationPermission, optedOut, serviceWorkerActive]) => {
                            console.log('Is Completely Subscribed:', isSubscribed);
                            console.log('');
                            console.log('What is our OneSignal user ID?', userId);
                            if (userId === undefined || userId === null) {
                                console.log('cannot update played id - is null');
                            } else {
                                console.log('played id' + userId);
                            }


                            // console.log('Setting email')
                            // OneSignal.setEmail(rootState.AppActiveUser.email);

                            console.log('emailId', emailId);

                            console.log('What is our push subscription token?', registrationId);
                            console.log('What is the notification permission status?', notificationPermission);
                            console.log('Are you manually opted out?', optedOut);
                            console.log("Is a service worker registered and active? (should be false on Safari, otherwise should be 'Worker A (Main)')?", serviceWorkerActive);
                            console.log('What is the current URL of this page?', location.href);
                            console.log("What environment does OneSignal think it's in?", OneSignal.sdkEnvironment.getWindowEnv());
                        })
                        .catch(e => {
                            console.error("Issue determining whether push is enabled:", e);
                        });
                    // ------ ONESIGNAL -------
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [LOGINCVTAS](context, credentials) {
        return new Promise((resolve, reject) => {
            console.log(context, credentials);
            ApiService.get("?action=login&username=" + credentials.email + "&password=" + credentials.password, true)
                .then((result) => {
                    // 
                    // ------ ONESIGNAL -------
                    // OneSignal.setEmail(rootState.AppActiveUser.email);
                    Promise.all([
                            OneSignal.isPushNotificationsEnabled(),
                            OneSignal.getUserId(),
                            OneSignal.getEmailId(),
                            OneSignal.getRegistrationId(),
                            OneSignal.getNotificationPermission(),
                            OneSignal.isOptedOut(),
                            // OneSignal.context.serviceWorkerManager.getActiveState()
                        ])
                        .then(([isSubscribed, userId]) => {
                            console.log('Is Completely Subscribed:', isSubscribed);
                            console.log('');
                            console.log('What is our OneSignal user ID?', userId);
                            if (userId === undefined || userId === null) {
                                console.log('cannot update played id - is null');
                            } else {
                                console.log('played id' + userId);
                                let requestParams = {
                                    "device_id": userId
                                };
                                ApiService.post("?object=users&action=edit&key=" + credentials.email, requestParams)
                                    .then((result3) => {
                                        resolve(result3);
                                    }).catch((response2) => {
                                        console.log(response2)
                                        context.commit(SET_ERROR, response2.data.errors);
                                    });
                            }
                        })
                        .catch(e => {
                            console.error("Issue determining whether push is enabled:", e);
                        });
                    // ------ ONESIGNAL -------
                    console.log('data: ', JSON.parse(result))
                    context.commit(SET_AUTH, JSON.parse(result));
                    ApiService.get("?object=users&action=view&key=" + credentials.email)
                        .then((result2) => {
                            resolve(result);
                            context.commit(SET_USER, JSON.parse(result2));
                        }).catch((response2) => {
                            console.log(response2)
                            context.commit(SET_ERROR, response2.data.errors);
                            reject(response2);
                        });

                })
                .catch((response) => {
                    console.log(response)
                    reject(response);
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
    [REGISTER](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("login", credentials)
                .then(({ data }) => {
                    context.commit(SET_AUTH, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
    },
    [VERIFY_AUTH](context) {
        console.log('verifying auth');
        if (JwtService.getToken()) {
            ApiService.get("?object=users&action=view&key=" + JwtService.getUserEmail())
                .then((result2) => {
                    console.log(typeof(result2));
                    console.log('VERIFY AUTH RESULT2: ', result2);
                    if (result2) {
                        context.commit(SET_USER, JSON.parse(result2));
                    } else {
                        context.commit(PURGE_AUTH);
                    }

                }).catch((response2) => {
                    console.log(response2);
                    context.commit(PURGE_AUTH);
                    // context.commit(SET_ERROR, response2.data.errors);
                });
            // ApiService.get("verify")
            //     .then(({ data }) => {
            //         context.commit(SET_AUTH, data);
            //     })
            //     .catch(({ response }) => {
            //         context.commit(PURGE_AUTH);
            //         context.commit(SET_ERROR, response.data.errors);
            //     });
        } else {
            context.commit(PURGE_AUTH);
        }
    },
    [UPDATE_PASSWORD](context, payload) {
        const password = payload;

        return ApiService.put("password", password).then(({ data }) => {
            context.commit(SET_PASSWORD, data);
            return data;
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, user) {
        // console.log('SET_AUTH: ',user);
        state.isAuthenticated = true;
        state.errors = {};
        console.log(user.JWT);
        JwtService.saveToken(user.JWT);
    },
    [SET_USER](state, user) {
        JwtService.saveUserEmail(user.users.email);
        state.user = user.users;
    },
    [SET_PASSWORD](state, password) {
        state.user.password = password;
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        JwtService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
import Vue from "vue";

var MockAdapter = require("axios-mock-adapter");

// mock testing user accounts
const users = [{
        email: "crrh@demo.com",
        password: "demo123",
        token: "mgfi5juf74j",
        uid: '00',
        names: 'CRRH Admin',
        depto: 'CRRH'
    },
    {
        email: "etesa1@demo.com",
        password: "etesa2021",
        token: "mgfi5jud64j",
        uid: '99',
        names: 'Etesa',
        depto: 'Etesa'
    },
    {
        email: "ineter1@demo.com",
        password: "ineter2021",
        token: "mgfi5j2c64j",
        uid: '100',
        names: 'Etesa',
        depto: 'Etesa'
    },
    {
        email: "bm01",
        password: "bm$2021",
        token: "mgfd562c64j",
        uid: '101',
        names: 'Banco Mundial',
        depto: 'Banco Mundial'
    },
    {
        email: "costarica@demo.com",
        password: "costarica",
        token: "mgfi5juf742",
        uid: '02',
        names: 'Costa Rica',
        depto: 'País'
    },
    {
        email: "elsalvador@demo.com",
        password: "elsalvador",
        token: "mgfi5juf73j",
        uid: '03',
        names: 'El Salvador',
        depto: 'País'
    },
    {
        email: "honduras@demo.com",
        password: "honduras",
        token: "mgfi2juf74j",
        uid: '04',
        names: 'Honduras',
        depto: 'País'
    },
    {
        email: "nicaragua@demo.com",
        password: "nicaragua",
        token: "mgfi5kuf74j",
        uid: '05',
        names: 'nicaragua',
        depto: 'País'
    },
    {
        email: "belice@demo.com",
        password: "belice",
        token: "mgfi4juf74j",
        uid: '06',
        names: 'Belice',
        depto: 'País'
    },
    {
        email: "panama@demo.com",
        password: "panama",
        token: "mg2i5juf74j",
        uid: '07',
        names: 'Costa Rica',
        depto: 'Panamá'
    },
    {
        email: "guatemala@demo.com",
        password: "guatemala",
        token: "mgfi5luf74j",
        uid: '08',
        names: 'Guatemala',
        depto: 'País'
    },
    {
        email: "neavilag@gmail.com",
        password: "navila",
        token: "mgfi5jpf74j",
        uid: '09',
        names: 'Norman Avila',
        depto: 'CRRH'
    },
    {
        email: "alejandro.solis@gmail.com",
        password: "asolis",
        token: "mgfi5jux74j",
        uid: '10',
        names: 'Alejandro Solis',
        depto: 'CRRH'
    },
    {
        email: "jmadrazo7@gmail.com",
        password: "amadrazo",
        token: "mgfiwjuf74j",
        uid: '11',
        names: 'Alejandro Madrazo',
        depto: 'CRRH'
    },
    {
        email: "rolando@dap.rocks",
        password: "rolando",
        token: "mgfiwjuf74j",
        uid: '11',
        names: 'Alejandro Madrazo',
        depto: 'CRRH'
    },
    // {
    //   email: "demo1@demo.com",
    //   password: "demo1",
    //   token: "mgfi1juf74j",
    //   uid: '12',
    //   names: 'Demo Uno',
    //   depto: 'CRRH'
    // },
    // {
    //   email: "demo2@demo.com",
    //   password: "demo2",
    //   token: "m3fi5juf74j",
    //   uid: '13',
    //   names: 'Demo Dos',
    //   depto: 'CRRH'
    // },
    // {
    //   email: "demo3@demo.com",
    //   password: "demo3",
    //   token: "mgfl5juf74j",
    //   uid: '13',
    //   names: 'Demo Tres',
    //   depto: 'CRRH'
    // },{
    //   email: 'grenderos@marn.gob.sv',
    //   password: 'demo20',
    //   token: 'mgfi5j2074j',
    //   uid: '20',
    //   names: 'Adriel Renderos',
    //   depto: 'MARN'
    //   },
    //   {
    //   email: 'jnavarro@imn.ac.cr',
    //   password: 'demo21',
    //   token: 'mgfi5j2174j',
    //   uid: '21',
    //   names: 'Jose Alberto Navarro',
    //   depto: 'IMN'
    //   },
    //   {
    //   email: 'christian.parada@marn.gob.sv',
    //   password: 'demo22',
    //   token: 'mgfi5j2274j',
    //   uid: '22',
    //   names: 'Christian José Palacios Parada',
    //   depto: 'MARN'
    //   },
    //   {
    //   email: 'mquiros@hidromet.com.pa',
    //   password: 'demo23',
    //   token: 'mgfi5j2374j',
    //   uid: '23',
    //   names: 'Marcos Quirós',
    //   depto: 'ETESA'
    //   },
    //   {
    //   email: 'lsoriano@marn.gob.sv',
    //   password: 'demo24',
    //   token: 'mgfi5j2474j',
    //   uid: '24',
    //   names: 'Lorena Rosaura Soriano de Cruz',
    //   depto: 'MARN'
    //   },
    //   {
    //   email: 'nmartinez@hidromet.com.pa',
    //   password: 'demo25',
    //   token: 'mgfi5j2574j',
    //   uid: '25',
    //   names: 'Nellys Martinez',
    //   depto: 'ETESA'
    //   },
    //   {
    //   email: 'bernyfallas@gmail.com',
    //   password: 'demo26',
    //   token: 'mgfi5j2674j',
    //   uid: '26',
    //   names: 'Berny Fallas',
    //   depto: 'Otro'
    //   },
    //   {
    //   email: 'esolano@imn.ac.cr',
    //   password: 'demo27',
    //   token: 'mgfi5j2774j',
    //   uid: '27',
    //   names: 'Eladio Solano León',
    //   depto: 'IMN'
    //   },
    //   {
    //   email: 'eyanez@hidromet.com.pa',
    //   password: 'demo28',
    //   token: 'mgfi5j2874j',
    //   uid: '28',
    //   names: 'Elicet M. Yañez V.',
    //   depto: 'ETESA'
    //   },
    //   {
    //   email: 'edominguezs@hidromet.com.pa',
    //   password: 'demo29',
    //   token: 'mgfi5j2974j',
    //   uid: '29',
    //   names: 'Edelia Domínguez',
    //   depto: 'ETESA'
    //   },
    //   {
    //   email: 'rcarrera@hidromet.com.pa',
    //   password: 'demo30',
    //   token: 'mgfi5j3074j',
    //   uid: '30',
    //   names: 'Russy Carrera',
    //   depto: 'ETESA'
    //   },
    //   {
    //   email: 'jorgedchinc@hotmail.com',
    //   password: 'demo31',
    //   token: 'mgfi5j3174j',
    //   uid: '31',
    //   names: 'Jorge Chinchilla',
    //   depto: 'INSIVUMEH'
    //   },
    //   {
    //   email: 'cmadrigal@recursoshidricos.org',
    //   password: 'demo32',
    //   token: 'mgfi5j3274j',
    //   uid: '32',
    //   names: 'Carlos Madrigal',
    //   depto: 'CRRH'
    //   },
    //   {
    //   email: 'eliseomartinez@marn.gob.sv',
    //   password: 'demo33',
    //   token: 'mgfi5j3374j',
    //   uid: '33',
    //   names: 'David Eliseo Martinez Castellanos',
    //   depto: 'MARN'
    //   },
    //   {
    //   email: 'rhenriquez@marn.gob.sv',
    //   password: 'demo34',
    //   token: 'mgfi5j3474j',
    //   uid: '34',
    //   names: 'Rafael Antonio',
    //   depto: 'MARN'
    //   },
    //   {
    //   email: 'estefana.velasquez@gmail.com',
    //   password: 'demo35',
    //   token: 'mgfi5j3574j',
    //   uid: '35',
    //   names: 'Estefana Mischelle Velasquez Lopez',
    //   depto: 'COPECO'
    //   },
    //   {
    //   email: 'manuel.prado@ineter.gob.ni',
    //   password: 'demo36',
    //   token: 'mgfi5j3674j',
    //   uid: '36',
    //   names: 'Manuel Prado',
    //   depto: 'INETER'
    //   },
    //   {
    //   email: 'vbenitez@hidromet.com.pa',
    //   password: 'demo37',
    //   token: 'mgfi5j3774j',
    //   uid: '37',
    //   names: 'Vianca Benítez',
    //   depto: 'ETESA'
    //   },
    //   {
    //   email: 'mmartinez@marn.gob.sv',
    //   password: 'demo38',
    //   token: 'mgfi5j3874j',
    //   uid: '38',
    //   names: 'JOSE MAURIIO MARTINEZ',
    //   depto: 'MARN'
    //   },
    //   {
    //   email: 'adlopezramos@gmail.com',
    //   password: 'demo39',
    //   token: 'mgfi5j3974j',
    //   uid: '39',
    //   names: 'Ana Deisy López',
    //   depto: 'CRRH'
    //   },
    //   {
    //   email: 'pabloayalamontenegro@gmail.com',
    //   password: 'demo40',
    //   token: 'mgfi5j4074j',
    //   uid: '40',
    //   names: 'Pablo E, Ayala',
    //   depto: 'MARN'
    //   },
    //   {
    //   email: 'bolmedo@recursoshidricos.org',
    //   password: 'demo41',
    //   token: 'mgfi5j4174j',
    //   uid: '41',
    //   names: 'Berta Olmedo',
    //   depto: 'CRRH'
    //   },
    //   {
    //   email: 'jybethancourt@hidromet.com.pa',
    //   password: 'demo42',
    //   token: 'mgfi5j4274j',
    //   uid: '42',
    //   names: 'Johisy Bethancourt',
    //   depto: 'ETESA'
    //   },
    //   {
    //     email: 'wstolz@imn.ac.cr',
    //     password: 'demo43',
    //     token: 'mgfi5j4374j',
    //     uid: '43',
    //     names: 'Werner Stolz',
    //     depto: 'IMN'
    //   },
    //   {
    //     email: 'reyna.mendoza05@gmai.com',
    //     password: 'demo44',
    //     token: 'mgfi5j4474j',
    //     uid: '44',
    //     names: 'Reyna Mendoza Sobalvarro',
    //     depto: 'INETER'
    //   },
    //   {
    //     email: 'eva.zavaleta@marn.gob.sv',
    //     password: 'demo45',
    //     token: 'mgfi5j4574j',
    //     uid: '45',
    //     names: 'Eva Judith Zavaleta Vásquez',
    //     depto: 'MARN'
    //   },
    //   {
    //     email: 'gchinchilla@imn.ac.cr',
    //     password: 'demo46',
    //     token: 'mgfi5j4674j',
    //     uid: '46',
    //     names: 'Gabriela Chinchilla',
    //     depto: 'IMN'
    //   },
    //   {
    //     email: 'amaldonado@hydromet.gov.bz',
    //     password: 'demo47',
    //     token: 'mgfi5j4774j',
    //     uid: '47',
    //     names: 'Akeisha Maldonado',
    //     depto: 'NMS'
    //     },
    //     {
    //     email: 'jairogaze@yahoo.es',
    //     password: 'demo48',
    //     token: 'mgfi5j4874j',
    //     uid: '48',
    //     names: 'Jairo García',
    //     depto: 'COPECO'
    //     },
    //     {
    //       email: 'mrodriguez@hidromet.com.pa',
    //       password: 'demo49',
    //       token: 'mgfi5j4974j',
    //       uid: '49',
    //       names: 'María Belén Rodríguez',
    //       depto: 'ETESA'
    //     },
    //     {
    //       email: 'jochepineda.1009@gmail.com',
    //       password: 'demo50',
    //       token: 'mgfi5j5074j',
    //       uid: '50',
    //       names: 'Jose Adalberto Pineda Bonilla ',
    //       depto: 'COPECO'
    //       },
    //       {
    //       email: 'bolmedo@recursoshidricos.org',
    //       password: 'demo51',
    //       token: 'mgfi5j5174j',
    //       uid: '51',
    //       names: 'Berta Olmedo',
    //       depto: 'CRRH'
    //       },
    //       {
    //       email: 'lenin.chavarria@rh.ineter.gob.ni',
    //       password: 'demo52',
    //       token: 'mgfi5j5274j',
    //       uid: '52',
    //       names: 'Lenin Chavarria',
    //       depto: 'INETER'
    //       },
    //       {
    //       email: 'marisol.aragon@rh.ineter.gob.ni',
    //       password: 'demo53',
    //       token: 'mgfi5j5374j',
    //       uid: '53',
    //       names: 'Marisol Aragon',
    //       depto: 'INETER'
    //       },
    //       {
    //       email: 'mjsales@insivumeh.gob.gt',
    //       password: 'demo54',
    //       token: 'mgfi5j5474j',
    //       uid: '54',
    //       names: 'Manuel Sales',
    //       depto: 'INSIVUMEH'
    //       },
    //       {
    //         email: 'khernandez@imn.ac.cr',
    //         password: 'demo55',
    //         token: 'mgfi5j5574j',
    //         uid: '55',
    //         names: 'Karina Hernández Espinoza',
    //         depto: 'IMN'
    //       },
    //       {
    //         email: 'luisgguirola@gmai.com',
    //         password: 'demo56',
    //         token: 'mgfi5j5674j',
    //         uid: '56',
    //         names: 'Luis Garcia',
    //         depto: 'Consultor'
    //       }

];

const MockService = {
    init() {
        // this sets the mock adapter on the default instance
        var mock = new MockAdapter(Vue.axios);

        // mock login request
        mock.onPost("/login").reply(data => {
            const credential = JSON.parse(data.data);
            const found = users.find(user => {
                return (
                    credential.email === user.email &&
                    credential.password === user.password
                );
            });
            if (found) {
                return [200, found];
            }
            return [404, { errors: ["The login detail is incorrect"] }];
        });

        // // mock info update request
        // mock.onPost("/personal_info").reply(data => {
        //     users.user_personal_info = data;
        //     return users.user_personal_info;
        // });

        // // mock to verify authentication
        // mock.onGet(/\/verify\/?/).reply(data => {
        //     const token = data.headers.Authorization.replace("Token ", "");
        //     if (token !== "undefined") {
        //         const found = users.find(user => {
        //             return token === user.token;
        //         });
        //         return [200, found];
        //     }
        //     return [401, { errors: ["Invalid authentication"] }];
        // });
    }
};

export default MockService;
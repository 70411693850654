import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

const apiURL = "https://927d1d30.us-south.apigw.appdomain.cloud/climahub-cvtas/api/"
    /**
     * Service to call HTTP request via Axios
     */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = "https://927d1d30.us-south.apigw.appdomain.cloud/climahub-cvtas/api/";
    },


    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        Vue.axios.defaults.headers.common[
            "X-Authorization"
        ] = `Bearer ${JwtService.getToken()}`;
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @returns {*}
     */
    get(resource, login = false) {
        console.log(resource);
        // 
        var myHeaders = new Headers();
        // append headers
        if (!login) {
            myHeaders.append("X-Authorization", "Bearer " + JwtService.getToken());
        }
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Connection", "keep-alive");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        return fetch(apiURL + resource, requestOptions)
            .then(response => response.text())
            .catch((error) => {
                console.log('ERROR IN GET RESPONSE: ', error);
            });
    },
    getNaked(resource) {
        console.log(resource);
        // 
        var myHeaders = new Headers();

        myHeaders.append("Accept", "*/*");
        myHeaders.append("Connection", "keep-alive");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        return fetch(resource, requestOptions)
            .then(response => response.text())
            .catch(error => console.log('error', error));
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        // return Vue.axios.post(`${resource}`, params);
        var myHeaders = new Headers();
        myHeaders.append("X-Authorization", "Bearer " + JwtService.getToken());
        myHeaders.append("Content-Type", "application/json");


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(params),
            redirect: 'follow'
        };

        return fetch(apiURL + resource, requestOptions)
            .then(response => response.text())
            .catch(error => console.log('error', error));
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch(error => {
            // console.log(error);
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export default ApiService;